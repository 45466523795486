.header {
  border-bottom: 1px solid $border-color;
  &__wrapper {
    margin: 25px 0 20px 0;
    width: 170px;
    @media (min-width: $min-md) {
      width: 200px;
    }
    @media (min-width: $min-xl) {
      width: 260px;
    }
  }
}
