.contact {
  margin: 0 0 45px 0;
  text-align: center;
  @media (min-width: $min-xl) {
    margin: 58px 0 0 0;

    text-align: left;
  }

  .h1 {
    margin-bottom: 39px;
  }
  p {
    font-size: 19px;
    line-height: 1.3;
    margin-bottom: 24px;
  }
  a {
    color: $gray;
    text-decoration: none;
    &.underline {
      text-decoration: underline;
    }
  }
}
