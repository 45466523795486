// colors
$primary-color: #0099ff;
$secondary-color: #ef1822;
$border-color: #ddd;

$white: #fffefe;
$black: #000;
$gray: #707070;

// breakpoints
$min-sm: 576px;
$min-md: 768px;
$min-lg: 992px;
$min-xl: 1200px;
$min-xxl: 1500px;
