*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  min-height: 100%;
}
body {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  font-family: "Open Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  margin: 0;
  padding-bottom: 70px;
  min-height: calc(100vh - 35px);
}

img {
  max-width: 100%;
  vertical-align: middle;
}

h1,
.h1 {
  color: $primary-color;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 39px;
  text-align: center;
  @media (min-width: $min-xl) {
    text-align: left;
  }
}
a:hover {
  color: $black;
}
.btn {
  position: relative;
  background: $secondary-color;
  color: $white;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  padding: 6px 30px;
  z-index: 100;
  border-radius: 5px;
  @media (min-width: $min-md) {
    font-size: 24px;
    padding: 9px 37px;
  }
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

p {
  color: $gray;
  font-size: 17px;
  margin-bottom: 41px;
  line-height: 1.6;
  text-align: center;
  @media (min-width: $min-xl) {
    text-align: left;
  }
}
@media (min-width: $min-xl) {
  .col-hero {
    padding-right: 0;
  }
  .col-contact {
    padding-left: 0;
  }
}
