footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;

  border-top: 1px solid $border-color;
  padding: 21px 0;
  font-size: 17px;
  text-align: center;
  @media (min-width: $min-xl) {
    text-align: left;
  }
  a {
    color: $primary-color;
    text-decoration: none;
  }
  p {
    margin-bottom: 0;
  }
}
