// PLUGINS
@import "../../node_modules/bootstrap-4-grid/scss/grid/bootstrap-grid.scss";

// slick
// $slick-font-path: "../fonts/" !default;
/*! purgecss start ignore */
// @import "../../node_modules/slick-carousel/slick/slick.scss";
// @import "../../node_modules/slick-carousel/slick/slick-theme.scss";
/*! purgecss end ignore */

// lightgallery
// $lg-path-images: '../images'!default;
/*! purgecss start ignore */
// @import "node_modules/lightgallery/src/sass/lightgallery";
/*! purgecss end ignore */

// sweet alert
/*! purgecss start ignore */
// @import "node_modules/sweetalert2/dist/sweetalert2";
/*! purgecss end ignore */

// aos
/*! purgecss start ignore */
// @import "../../node_modules/aos/dist/aos";
/*! purgecss end ignore */

// custom scrollbar
/*! purgecss start ignore */
// @import "../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar";
/*! purgecss end ignore */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import "config/variables";

@import "base";
@import "header";
@import "hero";
@import "contact";
@import "footer";
