.hero {
  margin: 47px 0 45px 0;

  @media (min-width: $min-xl) {
    margin: 47px 42px 96px 0;
    padding: 0 42px 0 0;
    border-right: 1px solid $border-color;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $min-md) {
      justify-content: space-between;
      flex-direction: row;

      margin-bottom: 28px;
      h1 {
        margin-bottom: 0;
      }
    }
    a {
      @media (max-width: $min-md - 1) {
        margin-bottom: 39px;
      }
    }
  }

  .bold {
    font-weight: 700;
  }
  &__img-wrapper {
    height: 285px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: 0.3s ease-in;
    &:hover {
      transform: scale(1.009);
    }
    @media (min-width: $min-md) {
      justify-content: flex-start;
    }
  }
  .btn {
    margin: 0 0 24px 26px;
  }
}
